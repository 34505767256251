import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import FeaturesFull from "../components/FeaturesFull";
import Instructors from "../components/Instructors";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const InspirersPageTemplate = ({
  image,
  content, contentComponent,
  title,
  heading,
  description,
  instructor,
  band,
  dj,
  fullImage,
}) => {
  const heroImage = getImage(image) || image;
  const fullWidthImage = getImage(fullImage) || fullImage;
  const PageContent = contentComponent || Content;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} imgPosition="center" />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <p>{description}</p>
                <PageContent className="content" content={content} />
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {instructor.heading}
                    </h3>
                    <p>{instructor.description}</p>
                  </div>
                </div>
                <Instructors instructors={instructor.blurbs} />
              </div>
            </div>
            <div className="columns">            
              <div className="column is-10 is-offset-1">
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {band.heading}
                    </h3>
                    <p>{band.description}</p>
                  </div>
                </div>
                <FeaturesFull gridItems={band.blurbs} /> 
              </div>
            </div>
            <div className="columns">            
              <div className="column is-10 is-offset-1">
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {dj.heading}
                    </h3>
                    <p>{dj.description}</p>
                  </div>
                </div>
                <Features gridItems={dj.blurbs} /> 
              </div>
            </div>
            <div className="columns">            
              <div className="column is-10 is-offset-1">
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="has-text-weight-semibold is-size-3">
                      And, SweetHeart
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FullWidthImage img={fullWidthImage} imgPosition={"center 15%"} />
    </div>
  );
};

InspirersPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  heading: PropTypes.string,
  description: PropTypes.string,
  instructor: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  band: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  dj: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const InspirersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <InspirersPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        contentComponent={HTMLContent}
        content={post.html}        
        heading={frontmatter.heading}
        description={frontmatter.description}
        instructor={frontmatter.instructor}
        band={frontmatter.band}
        dj={frontmatter.dj}
        fullImage={frontmatter.full_image}
      />
    </Layout>
  );
};

InspirersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default InspirersPage;

export const inspirersPageQuery = graphql`
  query InspirersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        instructor {
          blurbs {
            img1 {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
                }
              }
            }
            img2 {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
                }
              }
            }
            img3 {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
                }
              }
            }
            name
            text
          }
          heading
          description
        }
        band {
          heading
          description
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
        dj {
          heading
          description
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
        full_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
